import { SVGProps } from 'react';

export const SocialMedium = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12.4 10.3158C13.8322 10.3158 15.2057 10.9147 16.2184 11.9807C17.2311 13.0467 17.8 14.4925 17.8 16C17.8 17.5076 17.2311 18.9534 16.2184 20.0194C15.2057 21.0853 13.8322 21.6842 12.4 21.6842C10.9678 21.6842 9.59432 21.0853 8.58162 20.0194C7.56893 18.9534 7 17.5076 7 16C7 14.4925 7.56893 13.0467 8.58162 11.9807C9.59432 10.9147 10.9678 10.3158 12.4 10.3158ZM20.5 11.2632C21.85 11.2632 22.75 13.3843 22.75 16C22.75 18.6157 21.85 20.7368 20.5 20.7368C19.15 20.7368 18.25 18.6157 18.25 16C18.25 13.3843 19.15 11.2632 20.5 11.2632ZM24.1 11.7368C24.442 11.7368 24.7408 12.5203 24.892 13.8646L24.9343 14.2843L24.9514 14.507L24.9784 14.975L24.9874 15.2203L24.9982 15.7328L25 16L24.9982 16.2672L24.9874 16.7797L24.9784 17.026L24.9514 17.4931L24.9334 17.7157L24.8929 18.1354C24.7408 19.4806 24.4429 20.2632 24.1 20.2632C23.758 20.2632 23.4592 19.4797 23.308 18.1354L23.2657 17.7157C23.2595 17.6415 23.2538 17.5673 23.2486 17.4931L23.2216 17.0251C23.2181 16.9433 23.2151 16.8615 23.2126 16.7797L23.2018 16.2672V15.7328L23.2126 15.2203L23.2216 14.974L23.2486 14.507L23.2666 14.2843L23.3071 13.8646C23.4592 12.5194 23.7571 11.7368 24.1 11.7368Z"
        fill="currentColor"
      />
    </svg>
  );
};
