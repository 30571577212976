import React, { SVGProps } from 'react';

export const SocialWhatsUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93262 20.1328L5.06516 15.9904C4.36549 14.7752 3.99797 13.3967 3.99966 11.9938C4.00151 7.58596 7.58215 4 11.9822 4C14.1175 4.00109 16.1216 4.83351 17.6289 6.34456C19.1361 7.85561 19.9655 9.86412 19.9647 12.0002C19.9627 16.4077 16.3816 19.9943 11.9821 19.9943H11.9787C10.6428 19.9938 9.33017 19.6581 8.16421 19.0212L3.93262 20.1328ZM9.53819 8.30662C9.68021 8.31238 9.83752 8.31912 9.98702 8.6518C10.0888 8.87879 10.26 9.30147 10.3968 9.63906C10.4981 9.8892 10.5805 10.0926 10.6017 10.1351C10.6516 10.235 10.6847 10.352 10.6183 10.4851C10.6081 10.5056 10.5987 10.5249 10.5897 10.5433C10.5402 10.6447 10.5037 10.7195 10.4191 10.8182C10.3854 10.8577 10.3504 10.9002 10.3155 10.9427C10.2472 11.0259 10.1791 11.1089 10.1198 11.1682C10.0198 11.2677 9.91563 11.376 10.0322 11.5759C10.1487 11.7757 10.5489 12.4298 11.1418 12.9594C11.78 13.5295 12.3341 13.7702 12.6147 13.892C12.6691 13.9157 12.7133 13.9348 12.7457 13.9511C12.9454 14.051 13.0616 14.0342 13.178 13.9009C13.2944 13.7675 13.6762 13.3177 13.8093 13.1177C13.9424 12.9177 14.0753 12.9509 14.2581 13.0176C14.4409 13.0843 15.4213 13.5676 15.621 13.6676C15.6602 13.6873 15.6969 13.705 15.7309 13.7214C15.8697 13.7885 15.9634 13.8337 16.0034 13.9009C16.0532 13.9844 16.0531 14.3842 15.887 14.8508C15.7209 15.3174 14.9067 15.7674 14.5408 15.8007C14.5056 15.8039 14.4706 15.808 14.4346 15.8122C14.0966 15.852 13.67 15.9022 12.1474 15.3007C10.272 14.5599 9.03601 12.7231 8.78237 12.3462C8.76181 12.3157 8.74771 12.2947 8.74024 12.2847L8.73787 12.2815C8.62987 12.1369 7.9259 11.1939 7.9259 10.2183C7.9259 9.29858 8.37705 8.81627 8.58481 8.59417C8.59912 8.57887 8.61227 8.56481 8.62404 8.55192C8.80674 8.35194 9.02286 8.30194 9.15587 8.30194L9.53819 8.30662Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SocialWhatsUp;
