import React, { SVGProps } from 'react';

export const Upload = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M3.26409 5.32579L7.29294 1.29693C7.68346 0.906407 8.31662 0.906403 8.70715 1.29692L12.7342 5.32388C13.0857 5.67535 13.0857 6.2452 12.7342 6.59667C12.3828 6.94815 11.8129 6.94816 11.4614 6.59669L8.90005 4.03537L8.90005 10.5C8.90005 10.9971 8.4971 11.4 8.00005 11.4C7.50299 11.4 7.10005 10.9971 7.10005 10.5L7.10005 4.03541L4.53688 6.59858C4.18541 6.95005 3.61557 6.95005 3.26409 6.59858C2.91262 6.24711 2.91262 5.67726 3.26409 5.32579Z"
        fill="currentcolor"
      />
      <path
        d="M14.0976 14.9982C14.5947 14.9982 14.9976 14.5953 14.9976 14.0982C14.9976 13.6012 14.5947 13.1982 14.0976 13.1982L1.90232 13.1982C1.40526 13.1982 1.00232 13.6012 1.00232 14.0982C1.00232 14.5953 1.40526 14.9982 1.90232 14.9982L14.0976 14.9982Z"
        fill="currentcolor"
      />
    </svg>
  );
};
