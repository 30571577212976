import { SVGProps } from 'react';

export const SocialFacebook = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34315 6.3434C7.84344 4.84295 9.87827 4 12 4C14.1217 4 16.1566 4.84295 17.6569 6.3434C19.1571 7.84386 20 9.87891 20 12.0009C20 14.1228 19.1571 16.1579 17.6569 17.6584C16.1962 19.1192 14.2288 19.9568 12.1679 20C12.4779 19.9917 12.7821 19.9613 13.0829 19.9206V14.1221H15.1532L15.4783 12.0187H13.0825V10.8691C13.0825 9.99528 13.368 9.22046 14.1852 9.22046H15.4985V7.38489L15.4861 7.38322C15.2505 7.35136 14.7636 7.28551 13.8577 7.28551C11.9322 7.28551 10.8033 8.30247 10.8033 10.6194V12.0187H8.82397V14.1221H10.8033V19.9033C11.1379 19.9536 11.4761 19.9901 11.8214 19.9998C9.76437 19.9539 7.80129 19.1167 6.34315 17.6584C4.84285 16.1579 4 14.1228 4 12.0009C4 9.87891 4.84285 7.84386 6.34315 6.3434Z"
        fill="currentColor"
      />
    </svg>
  );
};
